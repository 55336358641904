<template>
  <div class='lang'>
    <el-select v-model="value" @change="handleChange" size="mini" style="margin-left:20px">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>

    <!-- <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">{{langText}}<i class="el-icon-arrow-down el-icon--right"></i></span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(t,i) in options" :command="t.value" :key="i">{{t.label}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->
  </div>
</template>
<script>
export default {
  name: "lang",
  data () {
    return {
      options: [{
        value: 'zh',
        label: '简体中文'
      }, {
        value: 'en',
        label: 'English'
      }, {
        value: 'fr',
        label: 'Français'
      },],

      value: 'fr',
      lang: 'fr',
      langText: "Français",
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleChange () {
      this.$i18n.locale = this.value;
      this.$storage.local.set("locale", this.value);
      this.$store.state.system.lang = this.value;
    },
    handleCommand (value) {
      this.$i18n.locale = this.lang = value;
      this.langText = this.options.find(function (o) { return o.value = value }).label;
      this.$storage.local.set("locale", value);
    }
  },
  mounted () {
    let locale = this.$storage.local.get("locale");
    if (locale)
      this.$i18n.locale = this.lang = this.value = locale
  },
}
</script>
<style lang='less'>
.lang {
  position: fixed;
  top: 1px;
  right: 20px;
  width: 120px;
  line-height: 48px;
  z-index: 11;
}
</style>