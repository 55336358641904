<template>
  <div id="qrcode" ref="qrcode"></div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  props: ['param'],
  data () {
    return {
      msg: "hello vue",
      codes: ""
    };
  },
  methods: {
    useqrcode () {
      new QRCode(document.getElementById('qrcode'), {
        text: this.param, //要生成二维码的网址
        width: 130,  //图像宽度
        height: 130, //图像高度
        colorDark: '#000000',  //前景色
        colorLight: '#ffffff', //背景色
        margin: 0, //外边距
        correctLevel: QRCode.CorrectLevel.L //容错级别。属性值有：L、M、Q、H
      })
    }
  },

  mounted () {
    // console.log(this.param)
    this.useqrcode();
  }
};
</script>
<style lang="less" scoped>
#qrCode {
  height: 100%;
  padding: 10px;
  position: relative;
  text-align: center;
  .canvas-container {
    // background-color: @white;
  }
  #canvas {
    width: 200px !important;
    height: auto !important;
  }
}
</style>