import ajax from '../assets/uilt/ajax';

let login = {
  /**(机构)机构登录接口 */
  orgLogin: req => { return ajax.post({ url: "/back/login/org-login", param: req.param, success: req.success }) },
  /**(机构)登出 */
  orgLogout: req => { return ajax.get({ url: "/back/login/org-logout", param: req.param, success: req.success }) },
  /**(机构)获取登录用户信息（树菜单等），无申请资料的新机构需要先跳转填写 */
  getInfo: req => { return ajax.get({ url: "/back/login/info", param: req.param, success: req.success }) },




  /**(客户)客户登录接口 */
  customerLogin: req => { return ajax.post({ url: "/back/login/customer-login", param: req.param, success: req.success }) },
  /**(客户)登出 */
  customerLogout: req => { return ajax.get({ url: "/back/login/customer-logout", param: req.param, success: req.success }) },
  /**(客户)获取登录用户信息（树菜单等） */
  getCustomerInfo: req => { return ajax.get({ url: "/back/login/customer-info", param: req.param, success: req.success }) },
  /**获取后台登录短信验证码 */
  getSmsCode: req => { return ajax.get({ url: "/back/login/get-sms", param: req.param, success: req.success }) },
  /**(客户)获取短信验证码（忘记密码） */
  getSmsByPhone: req => { return ajax.get({ url: "/back/login/get-sms-by-phone", param: req.param, success: req.success }) },
  /**(客户)忘记密码 修改密码 */
  forgetThePassword: req => { return ajax.post({ url: "/back/login/forget-the-password", param: req.param, success: req.success }) },
}

export default login;